import { applicationModalType } from '../../../utils/types';
import { enqueueSnackbar } from 'notistack';
import { handleErrors } from '../../../utils/functions';
import { hideAlert, showAlert } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useCallback, useMemo, useState } from 'react';
import useFetchs from './useFetchs';

const initialValues = {
    cargar: false,
    descargar: false,
    eliminar: false,
    idAplicacion: '',
    idTipoDocumento: '',
    modificar: false,
    requiereVencimiento: 'si',
    requiereCuilCuit: 'si',
    requiereCustomId: 'si',
    idsAplicacionesDescargas: [],
    prioridad: ''
};

function usePermissions() {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<any>(initialValues);
    const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
    const [openShowModal, setOpenShowModal] = useState<boolean>(false);
    const [filterModelValue, setFilterModelValue] = useState<string>('');
    const [auxAppDescargas, setAuxAppDescargas] = useState<applicationModalType[] | null>(null);
    const [newAppsDescargas, setNewAppsDescargas] = useState([]);
    const [showInput, setShowInput] = useState<boolean>(false);
    const [appIdToRemove, setAppIdToRemove] = useState<any>(null);

    const {
        addPermission,
        addDownloadApps,
        removeDownloadApp,
        deletePermission,
        refetchPermissions,
        items,
        documentTypes,
        applications,
        downloadApplications,
        ...fetchProps
    } = useFetchs({
        openSaveModal,
        openShowModal,
        data
    });

    const handleChange = (e: any) => {
        const { name, value, checked, type } = e?.target;
        if (type === 'checkbox') {
            setData({
                ...data,
                ...(name === 'descargar' &&
                    !!data?.idsAplicacionesDescargas?.length && {
                        idsAplicacionesDescargas: []
                    }),
                [name]: checked
            });
        } else {
            if (name === 'idAplicacion' && data?.idAplicacion) {
                setData({
                    ...initialValues,
                    [name]: value
                });
            } else {
                setData({
                    ...data,
                    ...(name === 'idTipoDocumento' &&
                        !!data?.idsAplicacionesDescargas?.length && {
                            idsAplicacionesDescargas: []
                        }),
                    [name]: value
                });
            }
        }
    };

    const handleRemoveDownloadApp = (app: applicationModalType) => {
        setAppIdToRemove(app.id);

        const submit = async () => {
            const response: any = await removeDownloadApp({ permissionId: data.id, appId: app.id });
            if (!response?.error) {
                let auxApplications = data?.aplicacionesDescarga?.filter(
                    (i: any) => i.id !== app?.id
                );
                setData({ ...data, aplicacionesDescarga: auxApplications });
                enqueueSnackbar(
                    response?.data?.message || 'Orígen autorizado eliminado correctamente.',
                    {
                        variant: 'success'
                    }
                );
                setAppIdToRemove(null);
            } else {
                handleErrors(response.error);
            }
        };

        dispatch(
            showAlert({
                title: '¿Eliminar orígen autorizado?',
                message: `Si eliminas la aplicación '${app?.nombre}' de los orígenes autorizados ya no estará disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                cancelAction: () => {
                    setAppIdToRemove(null);
                    dispatch(hideAlert());
                },
                confirmText: 'Sí, eliminar',
                confirmAction: submit
            })
        );
    };

    const handleDelete = useCallback((data: any) => {
        const submit = async () => {
            const response: any = await deletePermission(data?.row?.id);
            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Registro eliminado correctamente.', {
                    variant: 'success'
                });
            } else {
                handleErrors(response?.error);
            }
        };

        dispatch(
            showAlert({
                title: '¿Eliminar permiso?',
                message: `Si eliminas el permiso de '${data?.row?.aplicacion?.nombre}' ya no estará disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                confirmText: 'Sí, eliminar',
                confirmAction: submit
            })
        );
        // eslint-disable-next-line
    }, []);

    const onInputChange = useCallback((e: any) => {
        setFilterModelValue(e?.target?.value || '');
    }, []);

    const handleOpenShowModal = useCallback((data: any) => {
        setOpenShowModal(true);
        setData(data?.row);
        setAuxAppDescargas(data?.row?.aplicacionesDescarga);
    }, []);

    const handleCloseShowModal = () => {
        setOpenShowModal(false);
        setData(initialValues);
        setAuxAppDescargas(null);
        setNewAppsDescargas([]);
        setShowInput(false);
        if (!editPermissionBtnValidation) refetchPermissions();
    };

    const handleOpenSaveModal = () => {
        setData(initialValues);
        setOpenSaveModal(true);
    };

    const handleCloseSaveModal = () => {
        setOpenSaveModal(false);
        setData(initialValues);
    };

    const handleSubmit = async () => {
        const body = {
            ...data,
            idAplicacion: data?.idAplicacion?.id,
            idTipoDocumento: data?.idTipoDocumento?.id,
            idsAplicacionesDescargas: data?.idsAplicacionesDescargas?.map((i: any) =>
                i.id.toString()
            ),
            requiereCuilCuit: data?.requiereCuilCuit === 'si',
            requiereCustomId: data?.requiereCustomId === 'si',
            requiereVencimiento: data?.requiereVencimiento === 'si',
            prioridad: data?.prioridad?.id
        };

        const response: any = await addPermission(body);
        if (!response?.error) {
            handleCloseSaveModal();
            enqueueSnackbar(response?.data?.message || 'Los permisos fueron creados con éxito.', {
                variant: 'success'
            });
        } else {
            handleErrors(response?.error);
        }
    };

    const handleEditPermission = async () => {
        if (!!newAppsDescargas?.length) {
            const appIds = {
                idAplicaciones: newAppsDescargas?.map((app: any) => app?.id)
            };
            const response: any = await addDownloadApps({ appIds, permissionId: data?.id });

            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Datos guardados con éxito.', {
                    variant: 'success'
                });
                handleCloseShowModal();
            } else {
                handleErrors(response.error);
            }
        } else {
            handleCloseShowModal();
            refetchPermissions();
        }
    };

    const editPermissionBtnValidation = useMemo(() => {
        const isEqual = data?.aplicacionesDescarga?.length === auxAppDescargas?.length;
        return !data?.descargar || (!newAppsDescargas?.length && isEqual);
    }, [data, newAppsDescargas, auxAppDescargas]);

    const handleAddNewApps = (value: any) => {
        setNewAppsDescargas(value);
    };

    const appOptions = useMemo(() => {
        const applicationId = data?.aplicacion?.id || data?.idAplicacion?.id;

        const defaultApps = downloadApplications?.filter((i: any) => i.id !== applicationId);
        const showModalOptions = defaultApps?.filter(
            (i: any) => !data?.aplicacionesDescarga?.find((ad: any) => ad?.id === i?.id)
        );
        const saveModalOptions = defaultApps;

        return { showModalOptions, saveModalOptions };
    }, [downloadApplications, data]);

    //Filtrar si existe permiso para X tipoDocumento en alta de permisos
    const filteredDocumentTypes = useMemo(() => {
        const filtered = documentTypes?.filter(
            (dt: any) =>
                !items?.find(
                    (p: any) =>
                        p.aplicacion?.id === data.idAplicacion?.id && p.tipoDocumento?.id === dt?.id
                )
        );
        return filtered || [];
        // eslint-disable-next-line
    }, [documentTypes, items, data]);

    const disabledSubmitPermission = useMemo(() => {
        return !data?.cargar && !data?.descargar && !data?.eliminar;
    }, [data.cargar, data.descargar, data.eliminar]);

    return {
        data,
        items,
        showInput,
        applications,
        appIdToRemove,
        openShowModal,
        openSaveModal,
        auxAppDescargas,
        filterModelValue,
        editPermissionBtnValidation,
        filteredDocumentTypes,
        appOptions,
        disabledSubmitPermission,
        handleChange,
        handleSubmit,
        handleDelete,
        setShowInput,
        onInputChange,
        setOpenShowModal,
        handleAddNewApps,
        handleOpenSaveModal,
        handleOpenShowModal,
        handleCloseSaveModal,
        handleEditPermission,
        handleCloseShowModal,
        handleRemoveDownloadApp,
        ...fetchProps
    };
}

export default usePermissions;
