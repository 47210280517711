import { selectCurrentUser, setUserData } from '../../../redux/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useValidarTokenSesionMutation } from '../../../services/authApi';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

function UserValidation() {
    const user = useAppSelector(selectCurrentUser);
    const sesionId = sessionStorage.getItem('sesionid');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [validateSession, { isLoading }] = useValidarTokenSesionMutation();

    const getUserData = useCallback(async () => {
        const response: any = await validateSession({ sesionId: sesionId });

        if (!response?.error) {
            dispatch(
                setUserData({
                    nombre: response.data?.data?.nombre,
                    apellido: response.data?.data?.apellido,
                    cuil: response.data?.data?.cuil
                })
            );
            navigate('/app', { replace: true });
        } else {
            navigate('/unauthorized', { replace: true });
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (!user) getUserData();
        // eslint-disable-next-line
    }, [user]);

    return <LoadingComponent />;
}

export default UserValidation;
