import { enqueueSnackbar } from 'notistack';
import { handleErrors } from '../../../utils/functions';
import { showAlert } from '../../../redux/slices/appSlice';
import { newApplicationType } from '../../../utils/types';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useCallback, useState } from 'react';
import useDialog from '../../../hooks/useDialog';
import useFetchs from './useFetchs';

const initialValues = {
    nombre: '',
    cidi: '',
    vedi: '',
    descripcion: '',
    permiteUrlPublica: false,
    permisoFullDescarga: false
};

function useApplications() {
    const dispatch = useAppDispatch();
    const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const [data, setData] = useState<newApplicationType>(initialValues);
    const [filterModelValue, setFilterModelValue] = useState<string>('');
    const {
        addApplication,
        deleteApplication,
        updateApplication,
        reactivateApplication,
        ...fetchProps
    } = useFetchs();
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showInactiveApplications, setShowInactiveApplications] = useState<boolean>(false);

    const onInputChange = useCallback((e: any) => {
        setFilterModelValue(e?.target?.value || '');
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name, checked, type }: any = e?.target;
        setData({
            ...data,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleCloseMenu = () => {
        setSelectedItem(null);
        setAnchorEl(null);
    };

    const handleMenuAction = useCallback((params: any, event: any) => {
        setSelectedItem(params);
        setAnchorEl(event.currentTarget);
    }, []);

    const setOpenDialog = () => {
        setIsEditing(false);
        setData(initialValues);
        handleOpenDialog();
    };

    const handleDelete = useCallback((e: any) => {
        const submit = async () => {
            const response: any = await deleteApplication(e?.id);
            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Registro eliminado correctamente.', {
                    variant: 'success'
                });
            } else {
                handleErrors(response?.error);
            }
        };

        dispatch(
            showAlert({
                title: '¿Eliminar aplicación?',
                message: `Si eliminas '${e?.row?.nombre}' ya no estará disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                confirmText: 'Sí, eliminar',
                confirmAction: submit
            })
        );
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async () => {
        if (isEditing) {
            const response: any = await updateApplication({
                data: {
                    nombre: data.nombre,
                    descripcion: data.descripcion || '',
                    permiteUrlPublica: data.permiteUrlPublica,
                    permisoFullDescarga: data.permisoFullDescarga
                },
                appId: selectedItem.id
            });
            if (!response?.error) {
                enqueueSnackbar('La aplicación fué modificada con éxito.', {
                    variant: 'success'
                });
                handleCloseDialog();
            } else {
                handleErrors(response?.error);
            }
            setIsEditing(false);
        } else {
            const response: any = await addApplication(data);
            if (!response?.error) {
                enqueueSnackbar('La aplicación fue agregada con éxito.', {
                    variant: 'success'
                });
                setData(initialValues);
                handleCloseDialog();
            } else {
                handleErrors(response?.error);
            }
        }
    };

    const handleReactivate = useCallback((e: any) => {
        const submit = async () => {
            const response: any = await reactivateApplication(e?.id);
            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Registro dado de alta correctamente.', {
                    variant: 'success'
                });
            } else {
                handleErrors(response?.error);
            }
        };

        dispatch(
            showAlert({
                title: 'Dar de alta aplicación?',
                message: `Si das de alta '${e?.row?.nombre}' se encontrará nuevamente disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                confirmText: 'Sí, dar de alta',
                confirmAction: submit
            })
        );
        // eslint-disable-next-line
    }, []);

    const handleSubmitAction = (action: string) => {
        setAnchorEl(null);
        switch (action) {
            case 'edit':
                setIsEditing(true);
                setData({
                    nombre: selectedItem.row.nombre,
                    cidi: selectedItem.row.cidi || '',
                    vedi: selectedItem.row.vedi || '',
                    descripcion: selectedItem.row.descripcion || '',
                    permiteUrlPublica: selectedItem.row.permiteUrlPublica,
                    permisoFullDescarga: selectedItem.row.permisoFullDescarga
                });
                return handleOpenDialog();
            case 'inactivate':
                return handleDelete(selectedItem);
            case 'reactivate':
                return handleReactivate(selectedItem);
            default:
                return;
        }
    };

    const handleSwitchAppsInactivas = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked }: any = e?.target;
        setShowInactiveApplications(checked);
    };

    return {
        data,
        filterModelValue,
        openDialog,
        setOpenDialog,
        handleOpenDialog,
        handleCloseDialog,
        handleChange,
        onInputChange,
        handleDelete,
        handleSubmit,
        selectedItem,
        anchorEl,
        handleCloseMenu,
        handleMenuAction,
        handleSubmitAction,
        isEditing,
        handleSwitchAppsInactivas,
        showInactiveApplications,
        ...fetchProps
    };
}

export default useApplications;
