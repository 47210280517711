import { PermissionHeaders } from '../../utils/headers';
import DataTable from '../../components/Table/DataTable';
import SaveModal from './components/SaveModal';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import usePermissions from './hooks/usePermissions';
import ViewModal from './components/ViewModal';

function Permisos() {
    const {
        items,
        loadingPermissions,
        fetchingPermissions,
        filterModelValue,
        deletingPermission,
        onInputChange,
        handleDelete,
        handleOpenSaveModal,
        handleOpenShowModal,
        ...props
    } = usePermissions();

    return (
        <>
            <SectionTitle>
                Permisos definidos por aplicación sobre cada tipo de documento y formato de archivo.
            </SectionTitle>
            <SearchToolbar
                inputLabel="Buscar permisos"
                btnText="Nuevo permiso"
                onButtonClick={handleOpenSaveModal}
                onInputChange={onInputChange}
            />

            <DataTable
                options={items}
                handleDeleteAction={handleDelete}
                handleViewAction={handleOpenShowModal}
                loading={loadingPermissions || fetchingPermissions || deletingPermission}
                filterModelValue={filterModelValue}
                headers={PermissionHeaders}
                withActions={['view']}
                viewAction
            />

            <SaveModal {...props} />

            <ViewModal {...props} />
        </>
    );
}

export default Permisos;
