import {
    DOCIcon,
    DOCXIcon,
    JPGIcon,
    PDFIcon,
    PNGIcon,
    PPTIcon,
    PPTXIcon,
    XLSIcon,
    XLSXIcon
} from '../assets/icons/FileTypeIcons';
import { MIME_TYPES } from './constants';

export const FILE_TYPES_ICONS: { [key: string]: any } = {
    [MIME_TYPES.XLSX]: <XLSXIcon />,
    [MIME_TYPES.PPT]: <PPTIcon />,
    [MIME_TYPES.PDF]: <PDFIcon />,
    [MIME_TYPES.DOC]: <DOCIcon />,
    [MIME_TYPES.XLS]: <XLSIcon />,
    [MIME_TYPES.DOCX]: <DOCXIcon />,
    [MIME_TYPES.PPTX]: <PPTXIcon />,
    [MIME_TYPES.JPEG]: <JPGIcon />,
    [MIME_TYPES.PNG]: <PNGIcon />
};

export const NO_ROWS: { [key: string]: { path: string; buttonText: string } } = {
    aplicaciones: { path: 'aplicaciones', buttonText: 'Nueva aplicación' },
    'tipos-documentos': { path: 'tipos de documentos', buttonText: 'Nuevo documento' },
    'tipos-archivos': { path: 'tipos de archivos', buttonText: 'Nuevo archivo' },
    permisos: { path: 'permisos', buttonText: 'Nuevo permiso' }
};

export const REQUEST_RESPONSE: { [key: string]: string } = {
    error: 'Ha ocurrido un problema durante la creación de la aplicación. Por favor, intente nuevamente.'
};

export const DOWNLOAD_PUBLIC_URL =
    'Si esta opción se encuentra activa TODOS los documentos que la aplicación cargue en la API CDD podrán ser descargados a través de una URL pública por cualquier usuario que cuente con dicha URL.';

export const DOWNLOAD_FULL_PERMISSION =
    'Si esta opción se encuentra activa la aplicación tendrá acceso para descargar TODOS los documentos existentes en la API CDD.';

export const ACUMULABLE =
    'Si esta opción se encuentra activa se podrán subir múltiples documentos del mismo tipo para un mismo CUIL o CUIT propietario, en caso contrario podrá existir solamente UN DOCUMENTO de este tipo por cada CUIL o CUIT propietario.';

export const ORIG_AUTORIZADOS_LABEL =
    'Aplicaciones que tienen asignada la acción de carga para el tipo de documento seleccionado en el campo inmediatamente anterior.';

export const FILETYPES_FULLNAME = [
    { id: 1, nombre: 'Hoja de cálculo Excel', extension: '.xls' },
    { id: 2, nombre: 'Hoja de cálculo Excel (2007)', extension: '.xlsx' },
    { id: 3, nombre: 'Presentación de Power Point', extension: '.ppt' },
    {
        id: 4,
        nombre: 'Presentación de Power Point con formato Open XML (2007)',
        extension: '.pptx'
    },
    { id: 5, nombre: 'Adobe Portable Document Format', extension: '.pdf' },
    { id: 6, nombre: 'Portable Network Graphics', extension: '.png' },
    { id: 7, nombre: 'Joint Photographic Experts Group', extension: '.jpeg' },
    { id: 8, nombre: 'Microsoft Word', extension: '.doc' },
    { id: 9, nombre: 'Documento Word con formato Open XML (2007)', extension: '.docx' }
];

export const FILE_TYPES = [
    { id: 1, nombre: MIME_TYPES.XLS },
    { id: 2, nombre: MIME_TYPES.XLSX },
    { id: 3, nombre: MIME_TYPES.PPT },
    { id: 4, nombre: MIME_TYPES.PPTX },
    { id: 5, nombre: MIME_TYPES.PDF },
    { id: 6, nombre: MIME_TYPES.PNG },
    { id: 7, nombre: MIME_TYPES.JPEG },
    { id: 8, nombre: MIME_TYPES.DOC },
    { id: 9, nombre: MIME_TYPES.DOCX }
];

export const FILE_EXTENSION = [
    { id: 1, nombre: '.xls' },
    { id: 2, nombre: '.xlsx' },
    { id: 3, nombre: '.ppt' },
    { id: 4, nombre: '.pptx' },
    { id: 5, nombre: '.pdf' },
    { id: 6, nombre: '.png' },
    { id: 7, nombre: '.jpeg' },
    { id: 8, nombre: '.doc' },
    { id: 9, nombre: '.docx' }
];
