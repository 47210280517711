import { documentType } from '../../../utils/types';
import { enqueueSnackbar } from 'notistack';
import { handleErrors } from '../../../utils/functions';
import { showAlert } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useCallback, useState } from 'react';
import useDialog from '../../../hooks/useDialog';
import useFetchs from './useFetchs';

const initialValues = { nombre: '', descripcion: '', idsTipoArchivo: [], acumulable: true };

function useDocumentTypes() {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<documentType>(initialValues);
    const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const { addDocumentType, deleteDocumentType, updateDocumentType, ...fetchProps } = useFetchs({
        openDialog
    });
    const [filterModelValue, setFilterModelValue] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const onInputChange = useCallback((e: any) => {
        setFilterModelValue(e?.target?.value || '');
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name, checked, type } = event?.target;
        setData({
            ...data,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleCloseMenu = () => {
        setSelectedItem(null);
        setAnchorEl(null);
    };

    const handleMenuAction = useCallback((params: any, event: any) => {
        setSelectedItem(params);
        setAnchorEl(event.currentTarget);
    }, []);

    const setOpenDialog = () => {
        setIsEditing(false);
        setData(initialValues);
        handleOpenDialog();
    };

    const handleDelete = useCallback((e: any) => {
        const submit = async () => {
            const response: any = await deleteDocumentType(e?.id);
            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Registro eliminado correctamente.', {
                    variant: 'success'
                });
            } else {
                handleErrors(response?.error);
            }
        };

        dispatch(
            showAlert({
                title: '¿Eliminar tipo de documento?',
                message: `Si eliminas '${e?.row?.nombre}' ya no estará disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                confirmText: 'Sí, eliminar',
                confirmAction: submit
            })
        );
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async () => {
        if (isEditing) {
            const response: any = await updateDocumentType({
                data: {
                    nombre: data.nombre,
                    idsTipoArchivo: data.idsTipoArchivo.map((item: any) => item.id.toString()),
                    descripcion: data.descripcion
                },
                documentTypeId: selectedItem.id
            });
            if (!response?.error) {
                enqueueSnackbar('El tipo de documento fué modificado con éxito.', {
                    variant: 'success'
                });
                handleCloseDialog();
            } else {
                handleErrors(response?.error);
            }
            setIsEditing(false);
        } else {
            const body: documentType = {
                nombre: data.nombre,
                idsTipoArchivo: data.idsTipoArchivo.map((item: any) => item.id.toString()),
                descripcion: data.descripcion,
                acumulable: data.acumulable
            };
            const response: any = await addDocumentType(body);
            if (!response?.error) {
                enqueueSnackbar(
                    response?.data?.message || 'El tipo de documento se agregó con éxito.',
                    {
                        variant: 'success'
                    }
                );
                setData(initialValues);
                handleCloseDialog();
            } else {
                handleErrors(response?.error);
            }
        }
    };

    const handleSubmitAction = (action: string) => {
        setAnchorEl(null);
        switch (action) {
            case 'edit':
                setIsEditing(true);
                setData({
                    nombre: selectedItem.row.nombre,
                    descripcion: selectedItem.row.descripcion,
                    idsTipoArchivo: selectedItem.row.tiposArchivo,
                    acumulable: selectedItem.row.acumulable
                });
                return handleOpenDialog();
            case 'delete':
                return handleDelete(selectedItem);
            default:
                return;
        }
    };

    return {
        data,
        openDialog,
        filterModelValue,
        handleSubmit,
        handleChange,
        handleDelete,
        setOpenDialog,
        handleOpenDialog,
        handleCloseDialog,
        onInputChange,
        handleMenuAction,
        anchorEl,
        isEditing,
        handleCloseMenu,
        handleSubmitAction,
        ...fetchProps
    };
}

export default useDocumentTypes;
